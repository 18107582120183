import { useRef, useState } from "react";
import { useAuth } from "../../provider/auth";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
import "./playerV2.css";

/* 
  http://localhost:3000/?config=watch&video_provider=youtube&video_id=fJ9rUzIMcZQ
  http://localhost:3000/?config=watch&video_provider=vimeo&video_id=325654952

  - video_provider: "youtube" | "vimeo"
  - video_id: ID del video en la plataforma correspondiente
*/
function PlayerV2() {
  let player = useRef(null);
  let videoSrc;
  const auth = useAuth();
  const { video_provider: provider, video_id: videoId } = auth?.auth?.cookies || {};
  const [muted, setMuted] = useState(true);

  if (!videoId) {
    return (
      <div className="player-container">
        <p className="no-video-message">No hay un video disponible para reproducir.</p>
      </div>
    );
  }

  if (provider === "youtube") {
    videoSrc = `youtube/${videoId}`;
  } else if (provider === "vimeo") {
    videoSrc = `vimeo/${videoId}`;
  } else {
    videoSrc = null;
  }

  if (!videoSrc) {
    return (
      <div className="player-container">
        <p className="error-message">Proveedor de video no válido.</p>
      </div>
    );
  }

  return (
    <>
      {auth.auth.cookies && auth.auth.cookies.video_id && (
        <div className="player-container">
          <MediaPlayer ref={player} className="player" src={videoSrc} autoPlay muted={muted}>
            <MediaProvider />
            <DefaultVideoLayout icons={defaultLayoutIcons} />
          </MediaPlayer>

          {/* Botón de desmutear */}
          <div
            className={`unmute-button-container ${muted ? "muted" : "unmuted"}`}
            onClick={() => {
              setMuted(false);
            }}
          >
            <div className="unmute-button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" className="unmute-icon">
                <path d="M671-177q-11 7-22 13t-23 11q-15 7-30.5 0T574-176q-6-15 1.5-29.5T598-227q4-2 7.5-4t7.5-4L480-368v111q0 27-24.5 37.5T412-228L280-360H160q-17 0-28.5-11.5T120-400v-160q0-17 11.5-28.5T160-600h88L84-764q-11-11-11-28t11-28q11-11 28-11t28 11l680 680q11 11 11 28t-11 28q-11 11-28 11t-28-11l-93-93Zm89-304q0-83-44-151.5T598-735q-15-7-22-21.5t-2-29.5q6-16 21.5-23t31.5 0q97 43 155 131t58 197q0 33-6 65.5T817-353q-8 22-24.5 27.5t-30.5.5q-14-5-22.5-18t-.5-30q11-26 16-52.5t5-55.5ZM591-623q33 21 51 63t18 80v10q0 5-1 10-2 13-14 17t-22-6l-51-51q-6-6-9-13.5t-3-15.5v-77q0-12 10.5-17.5t20.5.5Zm-201-59q-6-6-6-14t6-14l22-22q19-19 43.5-8.5T480-703v63q0 14-12 19t-22-5l-56-56Z" />
              </svg>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PlayerV2;
