export class Cookies {
  constructor(adapter) {
    this.adapter = adapter;
  }

  setCookiesConfig() {
    switch (this.adapter.cookies.config) {
      case "inatrans":
        this.adapter.inatrans(this.adapter.cookies);
        break;
      case "admin":
        this.adapter.inatransAdmin(this.adapter.cookies);
        break;
      case "watch":
        this.adapter.watch(this.adapter.cookies);
        break;
      default:
        this.adapter.iframe(this.adapter.cookies);
        break;
    }
  }
}
