import  ReactDOM  from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from './provider/app'
import { AuthProvider } from './provider/auth'
//import { SnackbarProvider } from 'notistack'
import { App } from './App'
import { createRoot } from 'react-dom/client';




const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AppProvider>
      <AuthProvider>
        <App/>
      </AuthProvider>
    </AppProvider>
  </BrowserRouter>
);




